import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Open the `}<Link to="/features/mapselector" mdxType="Link">{`map selector`}</Link>{` and switch to the "Overlays" tab.`}</p>
    <br />
    <p>{`By default the tracks and trailheads are activated. Deactivate them to
see no content on top of the base map.`}</p>
    <Image src="features/overlays.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <h4>{`OSM MTB tracks`}</h4>
    <p>{`Get access to over 300.000 mountain bike tracks worldwide by activating
the "OSM MTB tracks" overlay.`}</p>
    <br />
    <p>{`"OSM" stands for `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/OpenStreetMap"
      }}>{`OpenStreetMap`}</a>{`,
a huge mapping project with more than 6 million contributors worldwide.`}</p>
    <br />
    <p>{`The overlay shows singletracks (no gravel or paved roads!) that are ridable
with the mountain bike. The color code is the same as for the Trailguide
tracks from green (very easy) to black (very difficult).`}</p>
    <br />
    <p>{`Zoom in to see the difficulty rating of the Singletrail Skala (S0 to S5)
and the trail names.`}</p>
    <Image src="features/osmtracks.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <h4>{`Strava heatmap`}</h4>
    <p>{`The strava heatmap shows the traffic intensity on the map. The brighter and
wider the lines, the more traffic. It is biking only data!`}</p>
    <br />
    <p>{`Use it to explore new areas, see where other bikers ride and if there's
traffic on a trail. It makes it easy to find a suitable uphill to a trail
you want to ride down.`}</p>
    <br />
    <p>{`When you activate an overlay, a button with an eye shows up. Move this
button right or up to adjust the transparency of the overlay making it
easy to see what is underneath the overlay.`}</p>
    <br />
    <p>{`Due to restrictions we can not use a very high quality and you will see
some pixels. For our purposes it works very well though.`}</p>
    <Image src="features/stravaoverlay.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Protected areas`}</h4>
    <p>{`The protected areas overlay shows nature reserves (brown), restricted
military zones (red) and other restricted areas.`}</p>
    <br />
    <p>{`Not all types of protected areas restrict the access. So make sure you do
more research. But it helps you to easily see if your planned tour will go
through a protected area.`}</p>
    <Image src="features/protectedareas.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <p>{`If you have selected an overlay option, the layer icon turns blue to indicate
active changes. The settings are retained if closing and restarting.`}</p>
    <br />
    <br />
    <h4>{`Show reviews on the map`}</h4>
    <p>{`When the "Reviews" tab is activated (in the list, not in the map selector),
the reviews are shown on the map. Find out more about the reviews tab here.
Close the list to get back to the map by clicking on the "x" button at the
top right.`}</p>
    <br />
    <p>{`Now you see circles on each trail head. The color indicates the average
rating, the diameter represents the number of reviews.`}</p>
    <br />
    <p>{`Click on a circle to see the individual reviews.`}</p>
    <Image src="features/reviewsmapandlist.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <Link className="float-left" to="/features/mapselector" mdxType="Link">
  ← Map selector
    </Link>
    <Link className="float-right" to="/features/login" mdxType="Link">
  Login →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      